import { useEffect } from 'react'
import { NavLink as Link } from 'react-router-dom'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from 'src/redux/app/app.hooks'
import * as routes from 'src/constants/routes'
import RidersIcon from 'src/assets/images/nav/riders-icon.svg'
import HuntersIcon from 'src/assets/images/nav/hunters-icon.svg'
import VehiclesIcon from 'src/assets/images/nav/vehicles-icon.svg'
import CampaignsIcon from 'src/assets/images/nav/campaigns-icon.svg'
import ZonesIcon from 'src/assets/images/nav/zones-icon.svg'
import MaasIcon from 'src/assets/images/nav/ic_api.svg'
import TuneIcon from 'src/assets/images/nav/tune-icon.svg'
import VmapIcon from 'src/assets/images/nav/vmap-icon.svg'
import HomeIcon from 'src/assets/images/nav/home-icon.svg'
import BatteryIcon from 'src/assets/images/nav/battery.svg'
import PermissionsIcon from 'src/assets/images/nav/permissions-icon.svg'
import RideInspectorIcon from 'src/assets/images/nav/ic_search.svg'
import AccessControl from 'src/components/app/AccessControl'
import DropDown from 'src/assets/images/nav/dropdown-icon.svg'
import RepairIcon from 'src/assets/images/nav/ic_repairs.svg'
import CollapseIcon from 'src/assets/images/nav/IconCollapseMenu.svg'
import IconMarket from 'src/assets/images/city_regs.svg'
import {
    expandZone,
    setNavExpanded,
    expandRepairs,
    expandVehicles,
    selectIsZoneExpanded,
    selectIsNavOpen,
    selectIsRepairsExpanded,
    selectIsVehiclesExpanded,
    selectIsRidersExpanded,
    expandRiders,
} from 'src/redux/nav'
import { ButtonText } from 'src/ui-kit/text/Text'
import * as colors from 'src/ui-kit/colors/colors'
import { permissions } from 'src/components/app/accessControl.permissions'
import { getPath } from 'src/components/app/utils/nav/navUtils'
import SupportSection from 'src/components/parts/navigation/SupportSection'
import { zIndex } from 'src/constants/zIndex'
import { AnimatedScooter } from 'src/components/parts/navigation/animatedScooter/AnimatedScooter'
import { isAppEnv } from 'src/utils/env'

const NAVBAR_EXPANDED_KEY = 'navbarExpanded'

interface Props {
    activeLink: string
}

export const DesktopNav = ({ activeLink }: Props) => {
    const isZoneExpanded = useAppSelector(selectIsZoneExpanded)
    const isNavOpen = useAppSelector(selectIsNavOpen)
    const isRepairsExpanded = useAppSelector(selectIsRepairsExpanded)
    const isRidersExpanded = useAppSelector(selectIsRidersExpanded)
    const isVehiclesExpanded = useAppSelector(selectIsVehiclesExpanded)

    const dispatch = useAppDispatch()

    useEffect(() => {
        const navVal = localStorage.getItem(NAVBAR_EXPANDED_KEY)
        if (navVal === null) {
            localStorage.setItem(NAVBAR_EXPANDED_KEY, 'true')
            dispatch(setNavExpanded(true))
        } else {
            dispatch(setNavExpanded(navVal === 'true'))
        }
    }, [dispatch])

    const expandNavbar = (value: boolean) => {
        localStorage.setItem(NAVBAR_EXPANDED_KEY, value.toString())
        dispatch(setNavExpanded(value))
    }

    const currentPath = getPath(activeLink)

    return (
        <NavigationWrapper $expanded={isNavOpen}>
            <ExpandNavBtn $expanded={isNavOpen}>
                <CollapseImg data-testid='collapse-nav' src={CollapseIcon} onClick={() => expandNavbar(!isNavOpen)} />
            </ExpandNavBtn>
            <Content>
                <ButtonText $color={colors.coral} style={{ marginLeft: '20px', height: '20px' }}>
                    {isNavOpen && 'MENU'}
                </ButtonText>
                <Link to={routes.OVERVIEW} data-testid='overview-navigation-link'>
                    <NavItem $expanded={isNavOpen} $active={currentPath === routes.OVERVIEW}>
                        <NavIcon $expanded={isNavOpen} src={HomeIcon} alt='' />
                        {isNavOpen && (
                            <ButtonText $verticalAlign $color={colors.white}>
                                HOME
                            </ButtonText>
                        )}
                    </NavItem>
                </Link>
                <AccessControl allow={permissions.hunter_editor}>
                    <Link to={routes.HUNTERS} data-testid='hunters-navigation-link'>
                        <NavItem
                            $expanded={isNavOpen}
                            $active={currentPath === routes.HUNTERS || currentPath === routes.ORG}
                        >
                            <NavIcon $expanded={isNavOpen} src={HuntersIcon} alt='' />
                            {isNavOpen && (
                                <ButtonText $verticalAlign $color={colors.white}>
                                    HUNTERS
                                </ButtonText>
                            )}
                        </NavItem>
                    </Link>
                </AccessControl>
                <AccessControl allow={permissions.users_viewer}>
                    <NavItem $noPadding $expanded={isNavOpen} $active={currentPath === routes.RIDERS}>
                        <Link
                            style={{ flex: '1', padding: isNavOpen ? '10px 10px' : undefined }}
                            to={routes.RIDERS}
                            data-testid='riders-navigation-link'
                        >
                            <Row style={{ justifyContent: !isNavOpen ? 'center' : undefined }}>
                                <NavIcon $expanded={isNavOpen} src={RidersIcon} alt='' />
                                {isNavOpen && (
                                    <ButtonText $verticalAlign $color={colors.white}>
                                        RIDERS
                                    </ButtonText>
                                )}
                            </Row>
                        </Link>
                        <AccessControl allow={permissions.accident_editor}>
                            {isNavOpen && (
                                <img
                                    src={DropDown}
                                    data-testid='expanded-riders-navbar'
                                    alt=''
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: 'auto',
                                        transform: isRidersExpanded ? '' : 'rotate(180deg)',
                                    }}
                                    onClick={() => dispatch(expandRiders(!isRidersExpanded))}
                                />
                            )}
                        </AccessControl>
                    </NavItem>
                    {isRidersExpanded && isNavOpen && (
                        <div>
                            <AccessControl allow={permissions.accident_editor}>
                                <NavItem $noPadding $active={currentPath === routes.ACCIDENT_REPORTS}>
                                    <Link
                                        data-testid='accident-reports-navigation-link'
                                        style={{
                                            flex: '1',
                                            display: 'flex',
                                            justifyContent: 'left',
                                            paddingLeft: '15px',
                                        }}
                                        to={routes.ACCIDENT_REPORTS}
                                    >
                                        <NavChildItem>
                                            <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                                - ACCIDENT REPORTS
                                            </ButtonText>
                                        </NavChildItem>
                                    </Link>
                                </NavItem>
                            </AccessControl>
                        </div>
                    )}
                </AccessControl>
                <Link to={routes.RIDE_INSPECTOR} data-testid='ride-inspector-navigation-link'>
                    <NavItem $expanded={isNavOpen} $active={currentPath === routes.RIDE_INSPECTOR}>
                        <NavIcon $expanded={isNavOpen} src={RideInspectorIcon} alt='Ride Inspector' />
                        {isNavOpen && (
                            <ButtonText $verticalAlign $color={colors.white}>
                                RIDE INSPECTOR
                            </ButtonText>
                        )}
                    </NavItem>
                </Link>
                <NavItem $noPadding $expanded={isNavOpen} $active={currentPath === routes.VEHICLES}>
                    <Link
                        style={{ flex: '1', padding: isNavOpen ? '10px 10px' : undefined }}
                        to={routes.VEHICLES}
                        data-testid='vehicles-navigation-link'
                    >
                        <Row style={{ justifyContent: !isNavOpen ? 'center' : undefined }}>
                            <NavIcon $expanded={isNavOpen} src={VehiclesIcon} alt='' />
                            {isNavOpen && (
                                <ButtonText $verticalAlign $color={colors.white}>
                                    VEHICLES
                                </ButtonText>
                            )}
                        </Row>
                    </Link>
                    <AccessControl allow={permissions.parking_assistant_editor}>
                        {isNavOpen && (
                            <img
                                src={DropDown}
                                data-testid='expanded-scooters-navbar'
                                alt=''
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: 'auto',
                                    transform: isVehiclesExpanded ? '' : 'rotate(180deg)',
                                }}
                                onClick={() => dispatch(expandVehicles(!isVehiclesExpanded))}
                            />
                        )}
                    </AccessControl>
                </NavItem>
                {isVehiclesExpanded && isNavOpen && (
                    <>
                        <NavItem $noPadding $active={currentPath === routes.PARKING_PHOTO_LABELLING}>
                            <Link
                                data-testid='parking-photo-labelling-navigation-link'
                                style={{ flex: '1', display: 'flex', justifyContent: 'left', paddingLeft: '15px' }}
                                to={routes.PARKING_PHOTO_LABELLING}
                            >
                                <NavChildItem>
                                    <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                        - PARKING PHOTO LABELLING
                                    </ButtonText>
                                </NavChildItem>
                            </Link>
                        </NavItem>
                        <NavItem $noPadding $active={currentPath === routes.SAFETY_CONTROL_SYSTEM}>
                            <Link
                                data-testid='safety-control-system-navigation-link'
                                style={{ flex: '1', display: 'flex', justifyContent: 'left', paddingLeft: '15px' }}
                                to={routes.SAFETY_CONTROL_SYSTEM}
                            >
                                <NavChildItem>
                                    <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                        - SAFETY CONTROL SYSTEM
                                    </ButtonText>
                                </NavChildItem>
                            </Link>
                        </NavItem>
                    </>
                )}
                <Link to={routes.VMAP} data-testid='vmap-navigation-link'>
                    <NavItem $expanded={isNavOpen} $active={currentPath === routes.VMAP}>
                        <NavIcon $expanded={isNavOpen} src={VmapIcon} alt='' />
                        {isNavOpen && (
                            <ButtonText $verticalAlign $color={colors.white}>
                                VEHICLE MAP
                            </ButtonText>
                        )}
                    </NavItem>
                </Link>
                {isAppEnv('stage') && (
                    <Link to={routes.BATTERIES} data-testid='batteries-navigation-link'>
                        <NavItem $expanded={isNavOpen} $active={currentPath === routes.BATTERIES}>
                            <NavIcon $expanded={isNavOpen} src={BatteryIcon} alt='' />
                            {isNavOpen && (
                                <ButtonText $verticalAlign $color={colors.white}>
                                    BATTERIES
                                </ButtonText>
                            )}
                        </NavItem>
                    </Link>
                )}
                <NavItem $noPadding $expanded={isNavOpen} $active={currentPath === routes.ZONES}>
                    <Link
                        style={{ flex: '1', padding: isNavOpen ? '10px 10px' : undefined }}
                        to={routes.ZONES}
                        data-testid='countries-navigation-link'
                    >
                        <Row style={{ justifyContent: !isNavOpen ? 'center' : undefined }}>
                            <NavIcon $expanded={isNavOpen} src={ZonesIcon} alt='' />
                            {isNavOpen && (
                                <ButtonText $verticalAlign $color={colors.white}>
                                    ZONES
                                </ButtonText>
                            )}
                        </Row>
                    </Link>
                    {isNavOpen && (
                        <img
                            src={DropDown}
                            data-testid='expanded-zones-navbar'
                            alt=''
                            style={{
                                cursor: 'pointer',
                                marginLeft: 'auto',
                                transform: isZoneExpanded ? '' : 'rotate(180deg)',
                            }}
                            onClick={() => dispatch(expandZone(!isZoneExpanded))}
                        />
                    )}
                </NavItem>
                {isZoneExpanded && isNavOpen && (
                    <>
                        <NavItem $noPadding $active={currentPath === routes.LEGAL}>
                            <Link
                                data-testid='legal-entities-navigation-link'
                                style={{ flex: '1', display: 'flex', justifyContent: 'left', paddingLeft: '15px' }}
                                to='/legal'
                            >
                                <NavChildItem>
                                    <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                        - LEGAL ENTITIES
                                    </ButtonText>
                                </NavChildItem>
                            </Link>
                        </NavItem>
                        <NavItem $noPadding={true} $active={currentPath === routes.PRICING_PORTAL}>
                            <Link
                                style={{ flex: '1', display: 'flex', justifyContent: 'left', paddingLeft: '15px' }}
                                to={routes.PRICING_PORTAL}
                            >
                                <NavChildItem>
                                    <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                        - PRICING
                                    </ButtonText>
                                </NavChildItem>
                            </Link>
                        </NavItem>
                        <NavItem $noPadding={true} $active={currentPath === routes.WORK_AREAS}>
                            <Link
                                style={{ flex: '1', display: 'flex', justifyContent: 'left', paddingLeft: '15px' }}
                                to={routes.WORK_AREAS}
                            >
                                <NavChildItem>
                                    <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                        - WORK AREAS
                                    </ButtonText>
                                </NavChildItem>
                            </Link>
                        </NavItem>
                    </>
                )}
                <Link to={routes.CONFIGHUB} data-testid='confighub-navigation-link'>
                    <NavItem $expanded={isNavOpen} $active={currentPath === routes.CONFIGHUB}>
                        <NavIcon $expanded={isNavOpen} src={TuneIcon} alt='' />
                        {isNavOpen && (
                            <ButtonText $verticalAlign $color={colors.white}>
                                CONFIG HUB
                            </ButtonText>
                        )}
                    </NavItem>
                </Link>
                <Link to={routes.CITYREGS} data-testid='cityregs-navigation-link'>
                    <NavItem $expanded={isNavOpen} $active={currentPath === routes.CITYREGS}>
                        <NavIcon $expanded={isNavOpen} src={IconMarket} alt='' />
                        {isNavOpen && (
                            <ButtonText $verticalAligns $color={colors.white}>
                                CITY REGS
                            </ButtonText>
                        )}
                    </NavItem>
                </Link>
                <AccessControl allow={permissions.partners_editor}>
                    <Link to={routes.DEEP_INTEGRATIONS} data-testid='deep-integrations-navigation-link'>
                        <NavItem $expanded={isNavOpen} $active={currentPath === routes.DEEP_INTEGRATIONS}>
                            <NavIcon $expanded={isNavOpen} src={MaasIcon} alt='' />
                            {isNavOpen && (
                                <ButtonText $verticalAlign $color={colors.white}>
                                    DEEP INTEGRATIONS
                                </ButtonText>
                            )}
                        </NavItem>
                    </Link>
                </AccessControl>
                <AccessControl allow={permissions.partners_editor}>
                    <Link to={routes.MAAS} data-testid='light-integrations-navigation-link'>
                        <NavItem $expanded={isNavOpen} $active={currentPath === routes.MAAS}>
                            <NavIcon $expanded={isNavOpen} src={MaasIcon} alt='' />
                            {isNavOpen && (
                                <ButtonText $verticalAlign $color={colors.white}>
                                    LIGHT INTEGRATIONS
                                </ButtonText>
                            )}
                        </NavItem>
                    </Link>
                </AccessControl>
                <Link to={routes.CAMPAIGNS} data-testid='campaigns-navigation-link'>
                    <NavItem $expanded={isNavOpen} $active={currentPath === routes.CAMPAIGNS}>
                        <NavIcon $expanded={isNavOpen} src={CampaignsIcon} alt='' />
                        {isNavOpen && (
                            <ButtonText $verticalAlign $color={colors.white}>
                                CAMPAIGNS
                            </ButtonText>
                        )}
                    </NavItem>
                </Link>
                <NavItem $noPadding $expanded={isNavOpen} $active={currentPath === routes.REPAIR_MANAGEMENT}>
                    <Link
                        style={{ flex: '1', padding: isNavOpen ? '10px 10px' : undefined }}
                        to={routes.REPAIR_MANAGEMENT}
                        data-testid='rms-navigation-link'
                    >
                        <Row style={{ justifyContent: !isNavOpen ? 'center' : undefined }}>
                            <NavIcon $expanded={isNavOpen} src={RepairIcon} alt='' />
                            {isNavOpen && (
                                <ButtonText $verticalAlign $color={colors.white}>
                                    REPAIRS
                                </ButtonText>
                            )}
                        </Row>
                    </Link>
                    {isNavOpen && (
                        <img
                            src={DropDown}
                            data-testid={isRepairsExpanded ? 'collapse-rms-navbar-btn' : 'expand-rms-navbar-btn'}
                            alt=''
                            style={{
                                cursor: 'pointer',
                                marginLeft: 'auto',
                                transform: isRepairsExpanded ? '' : 'rotate(180deg)',
                            }}
                            onClick={() => dispatch(expandRepairs(!isRepairsExpanded))}
                        />
                    )}
                </NavItem>
                {isRepairsExpanded && isNavOpen && (
                    <>
                        <NavItem $noPadding $active={currentPath === routes.ADMINISTRATION}>
                            <Link
                                data-testid='rms-administration-navigation-link'
                                style={{
                                    flex: '1',
                                    display: 'flex',
                                    justifyContent: 'left',
                                    paddingLeft: '15px',
                                }}
                                to='/administration'
                            >
                                <NavChildItem>
                                    <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                        - ADMINISTRATION
                                    </ButtonText>
                                </NavChildItem>
                            </Link>
                        </NavItem>
                        <NavItem $noPadding $active={currentPath === routes.WAREHOUSES}>
                            <Link
                                data-testid='warehouses-navigation-link'
                                style={{
                                    flex: '1',
                                    display: 'flex',
                                    justifyContent: 'left',
                                    paddingLeft: '15px',
                                }}
                                to={routes.WAREHOUSES}
                            >
                                <NavChildItem>
                                    <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                        - WAREHOUSES
                                    </ButtonText>
                                </NavChildItem>
                            </Link>
                        </NavItem>
                        <NavItem $noPadding $active={currentPath === routes.MAINTENANCE_NEEDS}>
                            <Link
                                data-testid='maintenance-needs-navigation-link'
                                style={{
                                    flex: '1',
                                    display: 'flex',
                                    justifyContent: 'left',
                                    paddingLeft: '15px',
                                }}
                                to='/maintenance-needs'
                            >
                                <NavChildItem>
                                    <ButtonText $verticalAlign $fontSize='12px' $color={colors.white}>
                                        - MAINTENANCE NEEDS
                                    </ButtonText>
                                </NavChildItem>
                            </Link>
                        </NavItem>
                    </>
                )}
                <Link to={routes.PERMISSIONS} data-testid='permissions-navigation-link'>
                    <NavItem $expanded={isNavOpen} $active={currentPath === routes.PERMISSIONS}>
                        <NavIcon $expanded={isNavOpen} src={PermissionsIcon} alt='' />
                        {isNavOpen && (
                            <ButtonText $verticalAligns $color={colors.white}>
                                PERMISSIONS
                            </ButtonText>
                        )}
                    </NavItem>
                </Link>
                <SupportSection expanded={isNavOpen} />
            </Content>
            {isNavOpen && <AnimatedScooter />}
        </NavigationWrapper>
    )
}

const NavigationWrapper = styled.div<{ $expanded: boolean }>`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: ${props => (props.$expanded ? '260px' : '70px')};
    transition: all 0.2s ease-in-out;
    background-color: ${colors.black};
    overflow: hidden auto;
`

const Content = styled.div`
    padding-top: 45px;
`

export const NavItem = styled.div<{ $noPadding?: true; $expanded?: boolean; $align?: string; $active?: boolean }>`
    display: flex;
    flex-direction: row;
    margin: 20px 10px;
    padding: ${props => (props.$noPadding && props.$expanded ? '0 10px 0 0' : '10px 10px')};
    cursor: pointer;
    border-radius: 12px;
    align-items: ${props => (props.$align ? props.$align : 'center')};
    opacity: ${props => (props.$active ? '1' : '0.5')};
    color: ${colors.white};

    &:hover {
        opacity: 1;
    }

    background-color: ${props => (props.$active ? colors.grey50 : '')};
    ${props => !props.$expanded && 'justify-content: center;'}
    transition: all .2s ease-in-out;
`

export const NavChildItem = styled.li`
    display: flex;
`

const ExpandNavBtn = styled.div<{ $expanded: boolean }>`
    position: fixed;
    left: ${props => (props.$expanded ? '234px' : '42px')};
    transform: ${props => (props.$expanded ? '' : 'scaleX(-1)')};
    top: 80px;
    cursor: pointer;
    z-index: ${zIndex.priority};
    display: flex;
    transition: all 0.2s ease-in-out;
`

export const NavIcon = styled.img<{ $expanded: boolean }>`
    margin-right: ${props => (props.$expanded ? '20px' : '0')};
    width: 20px;
    height: 20px;
`

const CollapseImg = styled.img`
    width: 100%;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
`
