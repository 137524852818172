import { put, takeEvery, call, select, type SagaReturnType } from 'redux-saga/effects'
import { type SagaIterator } from 'redux-saga'
import {
    updateHunterDetails,
    createNewHunter,
    deleteHunter,
    fetchHunterDetails,
    getHunterHistoryTasks,
    getAllOrganizations,
    getVehiclesByLongIds,
    resetHunterPassword,
    getOrganization,
} from 'src/api'
import { searchRiderByEmailOrId } from 'src/components/riders/riders-search/api'
import { notifyUser } from 'src/components/parts/notifications/notifications'
import {
    getHunter,
    getHunterHistory,
    setAllOrgs,
    setCurrentHunter,
    setHunterHistory,
    setHunterHistorySortParamForHunter,
    selectHunterTaskHistoryPageNum,
    selectHunter,
    selectCurrentHunterHistory,
    selectHunterHistorySortParam,
    selectHunterTaskHistoryEndOfPagination,
    incrementHunterTaskHistoryPagination,
    setIsFetchingHunterHistory,
} from 'src/redux/hunter'
import {
    appendTaskIdsWithParkingPhoto,
    setIsFetchingMoreTasksDone,
    resetHunterParkingPhotoState,
} from 'src/redux/hunterParkingPhotos'
import { addVehicleShortToTasks } from 'src/utils/hunters/huntersUtils'
import {
    DELETE_HUNTER,
    GET_ALL_ORGANIZATIONS,
    GET_HUNTER_HISTORY,
    GET_HUNTER_TASK_HISTORY_NEXT_PAGE,
    INCREMENT_HUNTER_TASK_HISTORY_PAGINATION,
    GET_HUNTER,
    SEARCH_HUNTER,
    SORT_HUNTER_HISTORY_FOR_HUNTER,
    SUBMIT_EDITED_HUNTER,
    SUBMIT_NEW_HUNTER,
    RESET_HUNTER_PASSWORD,
    type GetHunter,
    type SubmitEditedHunter,
    type SubmitNewHunter,
    type DeleteHunter,
    type SearchHunter,
    type SortHunterHistory,
    type ResetHunterPassword,
    type Hunter,
} from 'src/redux/hunter/hunter.types'
import { setCurrentOrganization } from 'src/redux/organization/organization.actions'
import { type Task } from 'src/api/fm/tasks/tasks.model'
import * as routes from 'src/constants/routes'

export function* getHunterHandler(action: GetHunter): SagaIterator {
    try {
        const res = yield call(fetchHunterDetails, action.payload)
        if (res instanceof Error) {
            throw res
        }
        yield put(setCurrentHunter(res))

        const orgRes = yield call(getOrganization, res.organizationId)
        if (orgRes instanceof Error) {
            throw orgRes
        }
        yield put(setCurrentOrganization(orgRes))
    } catch (e) {
        // @ts-ignore
        //TODO fix this
        yield put(setCurrentHunter({ error: 'Hunter not found' }))
        yield call(notifyUser, e, 'error')
    }
}

type UpdateHunterDetailsRes = SagaReturnType<typeof updateHunterDetails>

export function* submitEditedHunter(action: SubmitEditedHunter) {
    try {
        const hunter = action.payload
        const res: UpdateHunterDetailsRes = yield call(updateHunterDetails, hunter)
        if (res instanceof Error) {
            throw res
        }
        yield call(notifyUser, 'Successfully updated hunter information', 'success')
        yield put(getHunter(hunter.accountId))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

type CreateNewHunterRes = SagaReturnType<typeof createNewHunter>

export function* submitNewHunter(action: SubmitNewHunter) {
    try {
        const { hunter, navigateFn } = action.payload
        const res: CreateNewHunterRes = yield call(createNewHunter, hunter)
        if (res instanceof Error) {
            throw res
        }

        yield call(
            notifyUser,
            'Successfully created hunter account. An email to set up the password has been sent to the user.',
            'success',
        )
        // @ts-ignore https://github.com/redux-saga/redux-saga/issues/1943
        yield call(navigateFn, `${routes.ORG}/${hunter.organizationId}`)
    } catch (e: any) {
        if (e.response && e.response.data && e.response.data.errors && e.response.data.errors.length) {
            switch (e.response.data.errors[0].code) {
                case 'ErrHunterAlreadyExist':
                    yield call(notifyUser, { message: 'The hunter already exists.' }, 'error')
                    break
                case 'ErrInvalidInputEmail':
                    yield call(notifyUser, { message: 'Invalid applicant email.' }, 'error')
                    break
                case 'ErrCouldNotSendNewPassword':
                    yield call(
                        notifyUser,
                        {
                            message:
                                'Failed to send password to the hunter. Please reset password for the hunter manually.',
                        },
                        'error',
                    )
                    break
                default:
                    yield call(notifyUser, { message: e.response.data.errors[0].code }, 'error')
            }
        } else {
            yield call(notifyUser, e, 'error')
        }
    }
}

type DeleteHunterRes = SagaReturnType<typeof deleteHunter>

export function* deleteHunterHandler(action: DeleteHunter) {
    const { id, reason, navigateFn } = action.payload
    const res: DeleteHunterRes = yield call(deleteHunter, id, reason)

    if (res instanceof Error) {
        yield call(notifyUser, res)
    } else {
        // @ts-ignore https://github.com/redux-saga/redux-saga/issues/1943
        yield call(navigateFn, routes.HUNTERS)
        yield call(notifyUser, 'Successfully deleted hunter', 'success')
    }
}

type SearchRiderRes = SagaReturnType<typeof searchRiderByEmailOrId>

export function* searchHunter(action: SearchHunter) {
    try {
        const { searchTerm, navigateFn } = action.payload
        const foundRiders: SearchRiderRes = yield call(searchRiderByEmailOrId, searchTerm)
        if (foundRiders instanceof Error) {
            throw foundRiders
        }

        const hunter = foundRiders[0]
        if (hunter && hunter.userInfo) {
            // @ts-ignore https://github.com/redux-saga/redux-saga/issues/1943
            yield call(navigateFn, `${routes.HUNTERS}/${hunter.userInfo.id}`)
        }
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

type GetHunterHistoryTasksRes = SagaReturnType<typeof getHunterHistoryTasks>

export function* fetchHunterHistory(): SagaIterator {
    try {
        const hunter: Hunter = yield select(selectHunter)
        const sortParam: string = yield select(selectHunterHistorySortParam)
        const pageNumber: number = yield select(selectHunterTaskHistoryPageNum)

        yield put(setIsFetchingHunterHistory(true))

        const tasks: GetHunterHistoryTasksRes = yield call(
            getHunterHistoryTasks,
            hunter.accountId,
            pageNumber,
            10,
            sortParam,
        )
        if (tasks instanceof Error) {
            throw tasks
        }

        let newHistoryPage: Task[] = []

        if (tasks && tasks.length > 0) {
            const vehicleIds = tasks.filter(t => Boolean(t.vehicleId)).map(t => t.vehicleId)

            const vehicleRes = yield call(getVehiclesByLongIds, vehicleIds)
            const vehicles = !(vehicleRes instanceof Error) ? vehicleRes : []
            newHistoryPage = addVehicleShortToTasks(tasks, vehicles)
        }

        const currentHistory: Task[] = yield select(selectCurrentHunterHistory)
        // If page number is 1 we don't want to prepend any previously fetched history (from a separate sorting filter, etc.)
        const updatedHistory = pageNumber === 1 ? newHistoryPage : currentHistory.concat(newHistoryPage)

        const newTaskIdsWithPhoto = tasks.filter(t => t.hasPhoto).map(t => t.id)
        yield put(setHunterHistory(updatedHistory))
        yield put(appendTaskIdsWithParkingPhoto(newTaskIdsWithPhoto, 'hunter'))
    } catch (e) {
        yield call(notifyUser, { message: 'Failed to get hunter task history' }, 'error')
    } finally {
        yield put(setIsFetchingMoreTasksDone())
        yield put(setIsFetchingHunterHistory(false))
    }
}

function* getTaskHistoryNextPage() {
    const endOfPagination: boolean = yield select(selectHunterTaskHistoryEndOfPagination)
    if (!endOfPagination) {
        yield put(incrementHunterTaskHistoryPagination())
    }
}

function* sortHunterHistory(action: SortHunterHistory) {
    try {
        const currentSortParam: string = yield select(selectHunterHistorySortParam)
        const newSortParam = action.payload
        let param
        if (currentSortParam.substring(1) !== newSortParam) {
            param = '-' + newSortParam
        } else {
            param = currentSortParam.charAt(0) === '-' ? '%2B' + newSortParam : '-' + newSortParam
        }
        yield put(setHunterHistorySortParamForHunter(param))
        yield put(resetHunterParkingPhotoState())
        yield put(getHunterHistory())
    } catch (e) {
        console.log('Failed to sort hunter history', e)
    }
}

type GetAllOrganizationsRes = SagaReturnType<typeof getAllOrganizations>

export function* getAllOrgs() {
    try {
        const orgs: GetAllOrganizationsRes = yield call(getAllOrganizations)
        if (orgs instanceof Error) {
            throw orgs
        }
        yield put(setAllOrgs(orgs))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

type ResetHunterPasswordRes = SagaReturnType<typeof resetHunterPassword>

export function* resetPassword(action: ResetHunterPassword) {
    try {
        const email = action.payload
        const res: ResetHunterPasswordRes = yield call(resetHunterPassword, email)
        if (res instanceof Error) {
            throw res
        }
        yield call(notifyUser, 'Successfully sent an email to reset the password to the user.', 'success')
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

export default function* watcher() {
    yield takeEvery(GET_HUNTER, getHunterHandler)
    yield takeEvery(SUBMIT_EDITED_HUNTER, submitEditedHunter)
    yield takeEvery(SUBMIT_NEW_HUNTER, submitNewHunter)
    yield takeEvery(DELETE_HUNTER, deleteHunterHandler)
    yield takeEvery(SEARCH_HUNTER, searchHunter)
    yield takeEvery(GET_HUNTER_HISTORY, fetchHunterHistory)
    yield takeEvery(INCREMENT_HUNTER_TASK_HISTORY_PAGINATION, fetchHunterHistory)
    yield takeEvery(GET_HUNTER_TASK_HISTORY_NEXT_PAGE, getTaskHistoryNextPage)
    yield takeEvery(SORT_HUNTER_HISTORY_FOR_HUNTER, sortHunterHistory)
    yield takeEvery(GET_ALL_ORGANIZATIONS, getAllOrgs)
    yield takeEvery(RESET_HUNTER_PASSWORD, resetPassword)
}
