import axios from 'axios'
import { QueryClient } from 'react-query'
import { isAppEnv, APP_ENV } from 'src/utils/env'

function getBaseURL() {
    const subdomain = isAppEnv('production') ? '' : `.${APP_ENV}`
    return `https://api${subdomain}.voiapp.io/`
}

export const ins = axios.create({ baseURL: getBaseURL() })

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})
