import { Outlet } from 'react-router-dom'
import BatteryContextProvider from 'src/contexts/BatteryContext'

const BatteryContextLayout = () => {
    return (
        <BatteryContextProvider>
            <Outlet />
        </BatteryContextProvider>
    )
}

export default BatteryContextLayout
