import { type SagaIterator } from 'redux-saga'
import { put, takeEvery, call, select, delay, type SagaReturnType } from 'redux-saga/effects'
import { doc, setDoc } from 'firebase/firestore'
import {
    setCurrentRider,
    getCurrentRiderFullProfile,
    setRiderRideHistory,
    toggleEndRideModal,
    setAccountSharingInfo,
    setDiscountGroups,
    getRiderDiscount as getRiderDiscountAction,
    setRiderDiscount,
    setRideParkingPhoto,
    setDriversLicenseStatus,
    getDriversLicenseStatus as getDriversLicenseStatusAction,
    setActiveRideRider,
    setGuestRides,
    setRiderVoiPass,
    getRiderVoiPass as getRiderVoiPassAction,
    setCurrentRideHistoryItem,
    setRiderVoucherDetails as setRiderVoucherDetailsAction,
    getRiderVoucherDetails as getRiderVoucherDetailsAction,
    getAccountSharingInfo as getAccountSharingInfoAction,
    setRiderFineHistory,
    setRiderPaymentDetails,
    setDeleteUserFailed,
} from 'src/redux/rider/rider.actions'
import {
    selectCurrentRider,
    selectRideHistoryDateFilter,
    selectCurrentRideHistory,
    selectCurrentRiderDiscount,
} from 'src/redux/rider/rider.selectors'
import {
    GET_CURRENT_RIDER_FULL_PROFILE,
    GET_ACCOUNT_SHARING_INFO,
    GET_DISCOUNT_GROUPS,
    GET_RIDER_DISCOUNT,
    GIVE_RIDER_CREDITS,
    GIVE_RIDER_DISCOUNTS,
    FORGIVE_RIDER_DEBT,
    EDIT_RIDER_DISCOUNT,
    GET_RIDER_RIDE_HISTORY,
    GET_RIDE_PARKING_PHOTO,
    SUBMIT_END_RIDE,
    BLOCK_RIDER,
    DELETE_RIDER,
    GET_DRIVERS_LICENSE_STATUS,
    DELETE_DRIVERS_LICENSE,
    APPROVE_DRIVERS_LICENSE_DOCUMENT,
    APPROVE_DRIVERS_LICENSE_FACE_CHECK,
    GET_RIDER_VOI_PASS,
    EXPIRE_RIDER_VOI_PASS,
    GET_RIDE_HISTORY_ITEM,
    GET_RIDER_VOUCHER_DETAILS,
    DELETE_RIDER_VOUCHERS,
    UNBLOCK_RIDER_DEVICE_SWITCH,
    UPDATE_DATE_OF_BIRTH,
    DELETE_RIDER_PHONE,
    GET_RIDER_FINE_HISTORY,
    GET_RIDER_PAYMENT_DETAILS,
    REQUEST_ID_CHECK,
    type GetCurrentRiderFullProfile,
    type GetAccountSharingInfo,
    type GetRiderDiscount,
    type GetRiderVoiPass,
    type GiveRiderCredits,
    type GiveRiderDiscounts,
    type ForgiveRiderDebt,
    type EditRiderDiscount,
    type GetRiderRideHistory,
    type GetRideHistoryItem,
    type GetRideParkingPhoto,
    type SubmitEndRide,
    type BlockRider,
    type DeleteRider,
    type GetDriversLicenseStatus,
    type DeleteDriversLicense,
    type ApproveDriversLicenseDocument,
    type ApproveDriversLicenseFaceCheck,
    type ExpireRiderVoiPass,
    type GetRiderVoucherDetails,
    type DeleteRiderVouchers,
    type UnblockRiderDeviceSwitch,
    type UpdateDateOfBirth,
    type ParkingPhoto,
    type DeleteRiderPhone,
    type GetRiderFineHistory,
    type GetRiderPaymentDetails,
    type RequestIDCheck,
} from 'src/redux/rider/rider.types'
import { createNote } from 'src/redux/note'
import { getRides } from 'src/redux/activeRides'
import {
    giveRiderCredit,
    giveRiderDiscount,
    forgiveUserDebt,
    blockUser,
    unblockUser,
    deleteUser,
    getRider,
    getAccountSharingInfo,
    getDiscountGroups,
    getRiderDiscount,
    removeRiderDiscount,
    updateRiderDiscount,
    addRiderDiscount,
    blockUserFM,
    unblockUserFM,
    getRideParkingPhoto,
    endRide,
    getActiveRideUser,
    getRideHistoryUser,
    getDriversLicenseStatus,
    approveDriversLicenseDocument,
    approveDriversLicenseFaceCheck,
    deleteDriversLicense,
    getRiderVoiPass,
    expireRiderVoiPass,
    getRideHistoryById,
    getRiderVoucherHistory,
    deleteRiderVouchers as deleteRiderVouchersRequest,
    updateDateOfBirth,
    unblockDeviceSwitch,
    unlinkUserPhone,
    getRiderFineHistoryById,
    getRiderPaymentDetails,
    requestUserDocument,
} from 'src/api'
import { db } from 'src/firebase/firebase'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { notifyUser } from 'src/components/parts/notifications/notifications'
import { type RideHistoryItem } from 'src/api/fm/rentals/rentals.model'
import { type DriversLicenseStatus } from 'src/api/fm/users/users.model'
import { type RiderPaymentDetails, type RiderVoiPass } from 'src/api/fm/payments/payments.model'
import * as routes from 'src/constants/routes'
import { isAxiosError } from 'axios'

type GetRiderRes = SagaReturnType<typeof getRider>
type GetActiveRideUserRes = SagaReturnType<typeof getActiveRideUser>

export function* fetchCurrentRider(action: GetCurrentRiderFullProfile) {
    try {
        const id = action.payload
        const res: GetRiderRes = yield call(getRider, id)
        if (res instanceof Error) {
            throw res
        }
        // Set current rider
        yield put(setCurrentRider(res))

        // Set active ride
        const activeRides: GetActiveRideUserRes = yield call(getActiveRideUser, id)

        if (activeRides?.data) {
            yield put(setActiveRideRider(activeRides.data.data))
        }

        // set guest rides or clear it
        if (activeRides?.data.guest_rentals) {
            yield put(setGuestRides(activeRides.data.guest_rentals))
        } else {
            yield put(setGuestRides(null))
        }
    } catch (e) {
        yield put(setCurrentRider({ error: 'Rider not found' }))
    }
}

type GetAccountSharingInfoRes = SagaReturnType<typeof getAccountSharingInfo>

export function* fetchAccountSharingInfo(action: GetAccountSharingInfo): SagaIterator {
    const id = action.payload

    const accountSharingInfo: GetAccountSharingInfoRes = yield call(getAccountSharingInfo, id)

    if (accountSharingInfo instanceof Error) {
        yield call(notifyUser, accountSharingInfo, 'error')
        return
    }

    yield put(setAccountSharingInfo(accountSharingInfo))
}

type GetDiscountGroupsRes = SagaReturnType<typeof getDiscountGroups>

export function* fetchDiscountGroups() {
    const discountGroups: GetDiscountGroupsRes = yield call(getDiscountGroups)

    if (discountGroups instanceof Error) {
        yield call(notifyUser, discountGroups, 'error')
        return
    }

    yield put(setDiscountGroups(discountGroups))
}

type GetRiderDiscountRes = SagaReturnType<typeof getRiderDiscount>

export function* fetchRiderDiscount(action: GetRiderDiscount) {
    const id = action.payload
    const riderDiscount: GetRiderDiscountRes = yield call(getRiderDiscount, id)

    if (riderDiscount instanceof Error) {
        yield call(notifyUser, riderDiscount, 'error')
        return
    }

    yield put(setRiderDiscount(riderDiscount))
}

export function* fetchRiderVoiPass(action: GetRiderVoiPass) {
    const riderId = action.payload
    const voiPass: RiderVoiPass = yield call(getRiderVoiPass, riderId)

    if (voiPass instanceof Error) {
        yield call(notifyUser, voiPass, 'error')
        return
    }

    yield put(setRiderVoiPass(voiPass))
}

export function* fetchRiderPaymentDetails(action: GetRiderPaymentDetails) {
    const riderId = action.payload
    const paymentDetails: RiderPaymentDetails = yield call(getRiderPaymentDetails, riderId)

    if (paymentDetails instanceof Error) {
        yield call(notifyUser, paymentDetails, 'error')
        return
    }

    yield put(setRiderPaymentDetails(paymentDetails))
}

type GiveRiderCreditRes = SagaReturnType<typeof giveRiderCredit>

export function* giveCredits(action: GiveRiderCredits) {
    try {
        const res: GiveRiderCreditRes = yield call(giveRiderCredit, action.payload.riderId, action.payload.amount)
        if (res instanceof Error) {
            throw res
        }

        // Add a predefined note
        yield put(createNote(action.payload.note))
        yield put(getCurrentRiderFullProfile(action.payload.riderId))
        yield call(notifyUser, `Successfully added ${action.payload.amount} credits and a note`, 'success')
    } catch (e) {
        yield call(notifyUser, e)
    }
}

type GiveRiderDiscountsRes = SagaReturnType<typeof giveRiderDiscount>

function* giveDiscounts(action: GiveRiderDiscounts) {
    try {
        const res: GiveRiderDiscountsRes = yield call(
            giveRiderDiscount,
            action.payload.riderId,
            action.payload.name,
            // action.payload.description,
            action.payload.internalName,
            action.payload.expiresAt,
            action.payload.amount,
            action.payload.credits,
            action.payload.unlockFeeDiscountPercent,
            action.payload.rideFeeDiscountPercent,
            action.payload.rideFeeDiscountMinutes,
        )
        if (res instanceof Error) {
            throw res
        }

        // Add a predefined note
        yield put(createNote(action.payload.note))
        yield put(getCurrentRiderFullProfile(action.payload.riderId))
        yield call(notifyUser, `Successfully added discount and note`, 'success')
    } catch (e) {
        yield call(notifyUser, e)
    }
}

export function* forgiveDebt(action: ForgiveRiderDebt) {
    try {
        const { riderId } = action.payload
        yield call(forgiveUserDebt, riderId)
        yield put(getCurrentRiderFullProfile(riderId))
        yield call(notifyUser, 'Successfully deleted rider debt', 'success')
    } catch (e) {
        yield call(notifyUser, e)
    }
}

export function* editRiderDiscount(action: EditRiderDiscount): SagaIterator {
    const { riderId, discountGroupId, validTo } = action.payload
    const riderDiscount: Record<string, any> | null = yield select(selectCurrentRiderDiscount)
    const hasPrevDiscount = !!riderDiscount
    const hasNextDiscount = !!discountGroupId
    let result

    if (!hasNextDiscount) {
        result = yield call(removeRiderDiscount, riderDiscount?.id)
    } else if (hasPrevDiscount) {
        const data = {
            id: riderDiscount.id,
            group_id: discountGroupId,
            user_id: riderId,
            valid_from: 0,
            valid_to: validTo,
        }

        result = yield call(updateRiderDiscount, data)
    } else {
        const data = {
            group_id: discountGroupId,
            user_id: riderId,
            valid_from: 0,
            valid_to: validTo,
        }

        result = yield call(addRiderDiscount, data)
    }

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
        return
    }

    yield call(notifyUser, 'Successfully updated discount', 'success')
    yield put(getRiderDiscountAction(riderId))
}

type GetRideHistoryUserRes = SagaReturnType<typeof getRideHistoryUser>

export function* fetchRideHistory(action: GetRiderRideHistory) {
    try {
        const rider: Record<string, any> | null = yield select(selectCurrentRider)
        const date: string | null = yield select(selectRideHistoryDateFilter)
        const params = {
            source: rider?.source,
            page_size: 10,
            page_number: action.payload,
            date,
        }
        const history: GetRideHistoryUserRes = yield call(getRideHistoryUser, rider?.userInfo.id, params)
        if (history instanceof Error) {
            throw history
        }

        let updatedHistory
        if (action.payload > 0) {
            const currentHistory: RideHistoryItem[] | undefined = yield select(selectCurrentRideHistory)
            updatedHistory = currentHistory ? currentHistory.concat(history) : null
        } else {
            updatedHistory = history
        }
        yield put(setRiderRideHistory(updatedHistory))
    } catch (e) {
        console.log('Failed to get ride history', e)
    }
}

type GetRideHistoryByIdRes = SagaReturnType<typeof getRideHistoryById>

export function* fetchRideHistoryItem(action: GetRideHistoryItem) {
    const rideId = action.payload
    const ride: GetRideHistoryByIdRes = yield call(getRideHistoryById, rideId)

    if (ride instanceof Error) {
        yield call(notifyUser, ride, 'error')
        return
    }

    yield put(setCurrentRideHistoryItem(ride))
}

export function* fetchRideParkingPhoto(action: GetRideParkingPhoto) {
    const rideId = action.payload
    const rideParkingPhoto: ParkingPhoto = yield call(getRideParkingPhoto, rideId)

    if (rideParkingPhoto instanceof Error) {
        yield call(notifyUser, rideParkingPhoto, 'error')
        return
    }

    yield put(setRideParkingPhoto(rideParkingPhoto))
}

type EndRideRes = SagaReturnType<typeof endRide>

function* endActiveRide(action: SubmitEndRide) {
    try {
        const { user, ride, data } = action.payload

        const res: EndRideRes = yield call(endRide, {
            rental_id: ride?.rental_id!,
            newDuration: data.minutes,
            source: ride?.source!,
        })

        if (res instanceof Error) {
            throw res
        }

        if (data.note.title && data.note.desc) {
            const id = uuidv4()
            if (!db) {
                throw new Error('Database not initialized')
            }
            setDoc(doc(db, 'notes', id), {
                ...data.note,
                author: user?.email,
                type: 'rider',
                date: moment().format(),
                noteId: ride?.user_id,
                uuid: id,
            })
        }
        yield put(toggleEndRideModal(null))
        // Based on type, get up to date info
        if (data.type === 'active-rides') {
            yield put(getRides())
        }
        if (data.type === 'rider-profile') {
            if (user?.userId) {
                const activeRides: GetActiveRideUserRes = yield call(getActiveRideUser, user?.userId)
                if (activeRides?.data) {
                    yield put(setActiveRideRider(activeRides.data.data))
                } else {
                    yield put(setActiveRideRider(null))
                }

                // set guest rides or clear it
                if (activeRides?.data.guest_rentals) {
                    yield put(setGuestRides(activeRides.data.guest_rentals))
                } else {
                    yield put(setGuestRides(null))
                }
            }
        }
        notifyUser('Successfully ended ride', 'success')
    } catch (e) {
        const errors = isAxiosError(e) ? e.response?.data?.errors : null
        if (errors && errors.length > 0) {
            notifyUser({ message: errors[0].code }, 'error')
        } else {
            notifyUser(e, 'error')
        }
    }
}

type BlockUserRes = SagaReturnType<typeof blockUser>
type UnblockUserRes = SagaReturnType<typeof unblockUser>
type UnblockUserFMRes = SagaReturnType<typeof unblockUserFM>
type BlockUserFMRes = SagaReturnType<typeof blockUserFM>

export function* block(action: BlockRider) {
    try {
        const { id, blockDesc, blockTypes } = action.payload
        const user: Record<string, any> | null = yield select(selectCurrentRider)
        const isDriversLicenseBlocked = user?.drivingLicenceInfo && user.drivingLicenceInfo.blocked

        // Block cases
        if (blockTypes.user && !user?.userInfo.isBlocked) {
            const res: BlockUserRes = yield call(blockUser, id, blockDesc, 'user')
            if (res instanceof Error) {
                throw res
            }
            yield call(notifyUser, 'Successfully blocked user', 'success')
        }
        if (blockTypes.payment && !user?.paymentInfo.blocked) {
            const res: BlockUserFMRes = yield call(blockUserFM, id, blockDesc, 'payment')
            if (res instanceof Error) {
                throw res
            }
            yield call(notifyUser, "Successfully blocked user's payment", 'success')
        }
        if (blockTypes.device && !user?.userInfo.blockedByDevice) {
            const res: BlockUserRes = yield call(blockUser, id, blockDesc, 'device')
            if (res instanceof Error) {
                throw res
            }
            yield call(notifyUser, "Successfully blocked user's device", 'success')
        }
        if (blockTypes.license && !isDriversLicenseBlocked) {
            const res: BlockUserFMRes = yield call(blockUserFM, id, blockDesc, 'driving_licence')
            if (res instanceof Error) {
                throw res
            }
            yield call(notifyUser, "Successfully blocked user's driver's license", 'success')
        }

        //Unblock cases
        if (!blockTypes.user && user?.userInfo.isBlocked) {
            const res: UnblockUserRes = yield call(unblockUser, id, 'user')
            if (res instanceof Error) {
                throw res
            }
            yield call(notifyUser, 'Successfully unblocked user', 'success')
        }
        if (!blockTypes.payment && user?.paymentInfo.blocked) {
            const res: UnblockUserFMRes = yield call(unblockUserFM, id, 'payment')
            if (res instanceof Error) {
                throw res
            }
            yield call(notifyUser, "Successfully unblocked user's payment", 'success')
        }
        if (!blockTypes.device && user?.userInfo.blockedByDevice) {
            const res: UnblockUserRes = yield call(unblockUser, id, 'device')
            if (res instanceof Error) {
                throw res
            }
            yield call(notifyUser, "Successfully unblocked user's device", 'success')
        }
        if (!blockTypes.license && isDriversLicenseBlocked) {
            const res: UnblockUserFMRes = yield call(unblockUserFM, id, 'driving_licence')
            if (res instanceof Error) {
                throw res
            }
            yield call(notifyUser, "Successfully unblocked user's driver's license", 'success')
        }

        // Needs delay to be able to get new values
        yield delay(1500)
        yield put(getCurrentRiderFullProfile(id))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

type DeleteUserRes = SagaReturnType<typeof deleteUser>

export function* deleteRider(action: DeleteRider) {
    const { id, reason, navigateFn } = action.payload
    const res: DeleteUserRes = yield call(deleteUser, id, reason)

    if (res instanceof Error) {
        yield put(setDeleteUserFailed(true))
    } else {
        yield call(notifyUser, 'Successfully deleted rider account', 'success')
        // @ts-ignore https://github.com/redux-saga/redux-saga/issues/1943
        yield call(navigateFn, routes.RIDERS)
    }
}

type RequestIDCheckRes = SagaReturnType<typeof requestUserDocument>

function* requestIDCheck(action: RequestIDCheck) {
    const { reason, userIds } = action.payload
    const res: RequestIDCheckRes = yield call(requestUserDocument, reason, userIds)

    if (res instanceof Error) {
        yield call(notifyUser, res, 'error')
    } else {
        yield call(notifyUser, 'Successfully request user document', 'success')
    }
}

export function* fetchDriversLicenseStatus(action: GetDriversLicenseStatus) {
    const riderId = action.payload
    const driversLicenseStatus: DriversLicenseStatus = yield call(getDriversLicenseStatus, riderId)

    if (driversLicenseStatus instanceof Error) {
        yield call(notifyUser, driversLicenseStatus, 'error')
        return
    }

    yield put(setDriversLicenseStatus(driversLicenseStatus))
}

export function* deleteRiderDriversLicense(action: DeleteDriversLicense) {
    const riderId = action.payload
    const result: DeleteDriversLicense = yield call(deleteDriversLicense, riderId)

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
        return
    }

    yield call(notifyUser, 'Successfully deleted document', 'success')
    yield put(getDriversLicenseStatusAction(riderId))
}

export function* updateDriversLicenseDocumentStatus(action: ApproveDriversLicenseDocument) {
    const riderId = action.payload
    const result: ApproveDriversLicenseDocument = yield call(approveDriversLicenseDocument, riderId)

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
        return
    }

    yield call(notifyUser, 'Successfully approved document', 'success')
    yield put(getDriversLicenseStatusAction(riderId))
}

export function* updateDriversLicenseFaceCheckStatus(action: ApproveDriversLicenseFaceCheck) {
    const riderId = action.payload
    const result: ApproveDriversLicenseFaceCheck = yield call(approveDriversLicenseFaceCheck, riderId)

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
        return
    }

    yield call(notifyUser, 'Successfully approved the face check', 'success')
    yield put(getDriversLicenseStatusAction(riderId))
}

type ExpireRiderVoiPassRes = SagaReturnType<typeof expireRiderVoiPass>

export function* terminateRiderVoiPass(action: ExpireRiderVoiPass) {
    const { riderId, ridePlanId } = action.payload
    const result: ExpireRiderVoiPassRes = yield call(expireRiderVoiPass, riderId, ridePlanId!)

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
        return
    }

    yield call(notifyUser, 'Successfully expired Voi Pass', 'success')
    yield put(getRiderVoiPassAction(riderId))
}

type GetRiderVoucherHistoryRes = SagaReturnType<typeof getRiderVoucherHistory>

function* fetchRiderVoucherHistory(action: GetRiderVoucherDetails) {
    try {
        const riderId = action.payload
        const result: GetRiderVoucherHistoryRes = yield call(getRiderVoucherHistory, riderId)
        if (result instanceof Error) {
            throw result
        }
        yield put(setRiderVoucherDetailsAction(result))
    } catch (e) {
        yield call(notifyUser, "Couldn't fetch the rider's voucher details", 'error')
    }
}

type DeleteRiderVouchersRes = SagaReturnType<typeof deleteRiderVouchersRequest>

function* deleteRiderVouchers(action: DeleteRiderVouchers) {
    try {
        const { voucherIds, riderId } = action.payload
        const res: DeleteRiderVouchersRes = yield call(deleteRiderVouchersRequest, voucherIds)
        if (res instanceof Error) {
            throw res
        }
        yield call(notifyUser, `Success!`, 'success')
        yield call(fetchRiderVoucherHistory, getRiderVoucherDetailsAction(riderId))
    } catch (e) {
        yield call(notifyUser, `Something went wrong`, 'error')
    }
}

type UnblockDeviceSwitchRes = SagaReturnType<typeof unblockDeviceSwitch>

function* unblockRiderDeviceSwitch(action: UnblockRiderDeviceSwitch) {
    try {
        const { id, reason } = action.payload
        const res: UnblockDeviceSwitchRes = yield call(unblockDeviceSwitch, id, reason)
        if (res instanceof Error) {
            throw res
        }
        yield call(notifyUser, 'Successfully unblocked!', 'success')
        yield call(fetchAccountSharingInfo, getAccountSharingInfoAction(id))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

type UpdateDateOfBirthRes = SagaReturnType<typeof updateDateOfBirth>

export function* updateRiderDateOfBirth(action: UpdateDateOfBirth) {
    try {
        const { dateOfBirth, riderId } = action.payload
        const res: UpdateDateOfBirthRes = yield call(updateDateOfBirth, riderId, dateOfBirth)
        if (res instanceof Error) {
            throw res
        }
        yield call(notifyUser, 'Successfully updated date of birth', 'success')
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

type UnlinkUserPhone = SagaReturnType<typeof unlinkUserPhone>

export function* unlinkRiderPhone(action: DeleteRiderPhone) {
    try {
        const res: UnlinkUserPhone = yield call(unlinkUserPhone, action.payload)
        if (res instanceof Error) {
            throw res
        }
        yield call(notifyUser, 'Success!', 'success')

        const riderResponse: GetRiderRes = yield call(getRider, action.payload)
        if (riderResponse instanceof Error) {
            throw riderResponse
        }
        // Set current rider
        yield put(setCurrentRider(riderResponse))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

type GetRiderFineHistoryById = SagaReturnType<typeof getRiderFineHistoryById>

function* fetchRiderFineHistory(action: GetRiderFineHistory) {
    try {
        const riderId = action.payload
        const result: GetRiderFineHistoryById = yield call(getRiderFineHistoryById, riderId)

        if (result instanceof Error) {
            throw result
        }
        yield put(setRiderFineHistory(result))
    } catch (e) {
        yield call(notifyUser, "Couldn't fetch the rider's fine history", 'error')
    }
}

export default function* watcher() {
    yield takeEvery(GET_CURRENT_RIDER_FULL_PROFILE, fetchCurrentRider)
    yield takeEvery(GET_ACCOUNT_SHARING_INFO, fetchAccountSharingInfo)
    yield takeEvery(GET_DISCOUNT_GROUPS, fetchDiscountGroups)
    yield takeEvery(GET_RIDER_DISCOUNT, fetchRiderDiscount)
    yield takeEvery(GET_RIDER_VOI_PASS, fetchRiderVoiPass)
    yield takeEvery(GET_RIDER_PAYMENT_DETAILS, fetchRiderPaymentDetails)
    yield takeEvery(GIVE_RIDER_CREDITS, giveCredits)
    yield takeEvery(GIVE_RIDER_DISCOUNTS, giveDiscounts)
    yield takeEvery(FORGIVE_RIDER_DEBT, forgiveDebt)
    yield takeEvery(EDIT_RIDER_DISCOUNT, editRiderDiscount)
    yield takeEvery(GET_RIDER_RIDE_HISTORY, fetchRideHistory)
    yield takeEvery(GET_RIDE_HISTORY_ITEM, fetchRideHistoryItem)
    yield takeEvery(GET_RIDE_PARKING_PHOTO, fetchRideParkingPhoto)
    yield takeEvery(SUBMIT_END_RIDE, endActiveRide)
    yield takeEvery(BLOCK_RIDER, block)
    yield takeEvery(DELETE_RIDER, deleteRider)
    yield takeEvery(GET_DRIVERS_LICENSE_STATUS, fetchDriversLicenseStatus)
    yield takeEvery(DELETE_DRIVERS_LICENSE, deleteRiderDriversLicense)
    yield takeEvery(APPROVE_DRIVERS_LICENSE_DOCUMENT, updateDriversLicenseDocumentStatus)
    yield takeEvery(APPROVE_DRIVERS_LICENSE_FACE_CHECK, updateDriversLicenseFaceCheckStatus)
    yield takeEvery(EXPIRE_RIDER_VOI_PASS, terminateRiderVoiPass)
    yield takeEvery(GET_RIDER_VOUCHER_DETAILS, fetchRiderVoucherHistory)
    yield takeEvery(DELETE_RIDER_VOUCHERS, deleteRiderVouchers)
    yield takeEvery(UNBLOCK_RIDER_DEVICE_SWITCH, unblockRiderDeviceSwitch)
    yield takeEvery(UPDATE_DATE_OF_BIRTH, updateRiderDateOfBirth)
    yield takeEvery(DELETE_RIDER_PHONE, unlinkRiderPhone)
    yield takeEvery(GET_RIDER_FINE_HISTORY, fetchRiderFineHistory)
    yield takeEvery(REQUEST_ID_CHECK, requestIDCheck)
}
